import type {
    CourseGoal,
    HightLightReviewParams,
    ReviewDetail,
    ClassDetail,
    freeClass
} from '@/models/interface/course';
import courseApi from '@/services/courseApi';
import axiosInstance from '@/utils/axios';

export const useCourseStore = defineStore('course', () => {
    const freeClass: Ref<freeClass | ClassDetail | null> = ref({});
    const courseGoal = ref<CourseGoal>({
        goal: {
            currentQuantity: '00',
            targetQuantity: '00'
        }
        // time: {
        //     currentTime: '00',
        //     targetTime: '00'
        // }
    });

    /** 取得課程進度和時間目標 */
    const fnGetUserCourseGoals = async (type: number, memberLevel: number) => {
        const { goal } = await courseApi.getCourseGoalAndTime(type, memberLevel);

        Object.assign(courseGoal.value, {
            goal: { ...courseGoal.value.goal, ...goal }
            // time: { ...courseGoal.value.time, ...time }
        });
    };

    /**
     * 取得及時刻表課程
     * @param startTimeHour 起始小時(小時 0-23)
     * @param startTimeMinute 起始分鐘(小時 0-59)
     * @param endTimeHour 結束小時(小時 0-23)
     * @param classGroup 課程分類
     * @returns
     */
    const fnGetClassesByTime = async (
        startTimeHour: number,
        startTimeMinute: number,
        endTimeHour: number,
        classGroup: string[],
        classLevel: (number | string)[]
    ) => {
        // const memberId = parseInt(userStore.userData.memberId);
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const currentDate = `${year}-${month}-${day}`;

        const ClassLevel = classLevel[0] === -1 ? undefined : classLevel //目前是單一筆而已，my level如果要上要改

        const serializedParams = new URLSearchParams();
        serializedParams.append('StartDate', currentDate);
        serializedParams.append('EndDate', currentDate);
        serializedParams.append('StartTime', formatTime(startTimeHour, startTimeMinute));
        serializedParams.append('EndTime', formatTime(endTimeHour - 1, 59));
        if (classGroup) {
            classGroup.forEach(group => serializedParams.append('ClassGroup', group));
        }
        if (ClassLevel) {
            ClassLevel.forEach(level => serializedParams.append('ClassLevel', level.toString()));
        }
   

        const res = await axiosInstance.get(`/Schedule/GetClassesByTime`, {
            params: serializedParams
        });
        const data = res.data?.content;
        return data as ClassDetail;
        // return await courseApi.getCoursesByTime(serializedParams);
    };

    /**
     * 時間轉為時間字串hh:mm
     * @param hours 小時
     * @param minutes 分鐘
     * @returns
     */
    const formatTime = (hours: number, minutes = 0) => {
        // 限縮為有效的時間範圍 0:00~23:59
        if (hours < 0) {
            hours = 0;
        } else if (hours > 23) {
            hours = 23;
        }
        if (minutes < 0) {
            minutes = 0;
        } else if (minutes > 59) {
            minutes = 59;
        }
        // 使用 new Date() 設定指定的小時和分鐘
        const time = new Date(0, 0, 0, hours, minutes);

        // 取得小時和分鐘
        const formattedHours = time.getHours();
        const formattedMinutes = time.getMinutes();

        // 格式化時間字串
        const formattedTimeString = `${formattedHours
            .toString()
            .padStart(2, '0')}:${formattedMinutes.toString().padStart(2, '0')}`;

        return formattedTimeString;
    };

    /**
     * 取得該課程的候位人數
     * @param classId 課程ID
     * @returns
     */
    const fnGetCourseReserveStatus = async (classIDs: string[]): Promise<Record<string, Array<string>>> => {
        return await courseApi.getCourseReserveStatus(classIDs);
    };

    /**
     * 取得精選回顧影片(名師講堂 & 國高中課程)
     *
     * @param {string} classGroup 課程分類
     * @param {number} pg 分頁數
     * @returns {ReviewDetail[]} 回顧影片清單
     */
    const fnGetHightLightReview = async (
        classGroup: string,
        pg: number
    ): Promise<ReviewDetail[]> => {
        // console.log('[fnGetHightLightReview] classGroup:', classGroup);
        // console.log('[fnGetHightLightReview] pg:', pg);

        const params: HightLightReviewParams = {
            classGroup: classGroup,
            pg: pg
        };

        const res = await courseApi.getHightLightReview(params);
        // console.log('[fnGetHightLightReview] res:', res);
        return res as ReviewDetail[];
    };

    /** 播放下載課程影片
     * @param {string} classId 課程ID
     */
    const fnPlayVideo = async (classId: string, classGroup: string) => {
        const params = {
            classId,
            classGroup
        };
        return await courseApi.playVideo(params);
    };

    /** 取得當下即將開課的免費課程內容
     * 此為大講堂的免費公開課（右上會出現的那個）
     * @returns
     */
    const fnGetFreeCourse = async () => {
        freeClass.value = await courseApi.getFreeClass();
    };

    return {
        freeClass,
        courseGoal,
        fnGetUserCourseGoals,
        fnGetClassesByTime,
        fnGetCourseReserveStatus,
        fnGetHightLightReview, // 取得精選回顧影片
        fnPlayVideo, // 播放下載課程影片
        fnGetFreeCourse // 取得當下即將開課的免費課程內容
    };
});

const writingPageDefaultCategory = [
    '正規教室',
    '中班正規教室',
    '通識教室',
    '中班通識教室',
    '職場情境教室',
    '中班職場情境教室',
    '商務寫作教室',
    '中班商務寫作教室',
    '文法進修教室',
    '中班文法進修教室',
    '句型教室',
    '中班句型教室',
    '寫作教室',
    '中班寫作教室',
    '翻譯教室',
    '檢定班',
    '大講堂',
    '國高中課程'
];

const speakingPageDefaultCategory = [
    '咖啡廳',
    '生活會話教室',
    '英語聊天室',
    '語感教室',
    '英語大哉問',
    '商務教室',
    '中班商務教室',
    '視聽教室',
    'Funday Junior教室',
    '面談室',
    '實戰演練'
];

const noReservationClasses = [
    'lecture', // 名師講堂
    'he', // 國高中課程
    'hangout', // 英語聊天室
    'conference',
    'interviewroom',
    'askaway', // 學習相談室
    'pharmacyac', // 學習診療室
    'englishll', // 英語大哉問
    'coach'
];
export {
    writingPageDefaultCategory,
    speakingPageDefaultCategory,
    noReservationClasses
};
