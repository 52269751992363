import type { RouteLocationNormalized, NavigationGuardNext, Router } from 'vue-router';
import { useCourseStore } from '@/stores/course';
import { UtilCommon } from '@/utils/utilCommot';
import type { SettingHomePage } from '@/models/interface/storageValue';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';
import { RoleType } from '@/models/interface/user';

export default async function customHomeClass({
  to,
  from,
  next,
  router
}: {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  next: NavigationGuardNext;
  router: Router;
}) {
  const app = useAppStore();
  const user = useUserStore();
  const storage = UtilCommon.getLocalStorage<SettingHomePage[]>('customHome');

  if (!app.isMobile && user.userData.roleType !== RoleType['isJunior']) {
    const storageValue = storage?.find((item) => item.memberId === user.userData.memberId);

    if (user.isAfterSignUp && !storageValue) {
      window.location.href = '/custom-home-page';
    }
    if (from.path === '/login' && !storageValue) {
      window.location.href = '/custom-home-page';
    }
  }

  return next();
}
