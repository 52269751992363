import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import { createAppRouter } from '@/router/index';
import 'virtual:uno.css';
import '@unocss/reset/normalize.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import i18n from '@/plugins/i18n';
import 'virtual:svg-icons-register';
import 'animate.css';

import '@/assets/scss/style.scss';
import App from './App.vue';
import * as info from '../package.json';

const pinia = createPinia();
pinia.use(
  createPersistedState({
    storage: localStorage
  })
);
const { router } = createAppRouter();

const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.mount('#app');

declare global {
  interface Window {
    funday: string;
  }
}

window.funday = `funday(${info?.version})`;
